import { useTableOptions } from '@/composables'
import { fetchMemberships } from '@api/membership'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useMembershipList() {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'PLAN', value: 'plan_id' },
    { text: 'USER', value: 'user_id' },
    { text: 'GROUP OWNER', value: 'group_id' },
    { text: 'ACTIVE', value: 'active' },
    { text: 'STATUS', value: 'status' },
    { text: 'NEXT BILLING', value: 'next_billing_at' },
    { text: 'STARTED', value: 'started_at' },
    { text: 'ENDED', value: 'ended_at' },
    { text: 'RECURSION', value: 'recursion' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  const statusOptions = [
    { text: 'Free Trial', value: 'free_trial' },
    { text: 'Pending', value: 'pending' },
    { text: 'Started', value: 'started' },
    { text: 'Ended', value: 'ended' },
  ]

  const searchQuery = ref(cached[1] || '')
  const userQuery = ref(cached[2] || '')
  const statusFilter = ref(cached[3] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadMemberships = () =>
    fetchMemberships(
      useTableOptions(options.value, {
        search: searchQuery.value,
        email: userQuery.value,
        status: statusFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        listTable.value = records
        totalListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  loadMemberships()

  const cachable = [options, searchQuery, userQuery, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadMemberships()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    userQuery,
    statusFilter,
    options,
    statusOptions,
    listTable,
    totalListTable,
    loading,
    selectedTableData,
    loadMemberships,
  }
}
