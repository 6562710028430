var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-select',{staticClass:"invoice-list-status",attrs:{"items":_vm.statusOptions,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Select Status"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('v-text-field',{staticClass:"invoice-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search User Email"},model:{value:(_vm.userQuery),callback:function ($$v) {_vm.userQuery=$$v},expression:"userQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.listTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.trending",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons)+" ")])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.plan_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'checkout-membership-view', params: { id: item.id} }}},[_vm._v(" "+_vm._s(_vm.t(item.plan.sku.name))+" ")])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(_vm._s(item.user.email))]):_c('small',[_vm._v("(Not Yet Redeemed)")])]}},{key:"item.group_id",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(_vm._s(item.group.user.email))]):_vm._e()]}},{key:"item.next_billing_at",fn:function(ref){
var item = ref.item;
return [(item.next_billing_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.next_billing_at)))]):_vm._e(),(item.next_billing_at)?_c('span',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm._f("diff")(item.next_billing_at,'days'))+" days)")]):_vm._e()]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [(item.started_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.started_at)))]):_vm._e()]}},{key:"item.ended_at",fn:function(ref){
var item = ref.item;
return [(item.ended_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.ended_at)))]):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:("v-chip-light-bg " + (_vm.mapColor(item.active)) + "--text font-weight-semibold"),attrs:{"small":"","color":_vm.mapColor(item.active)}},[_vm._v(" "+_vm._s(item.active ? 'Active' : 'Ended')+" ")]),(item.ending)?_c('v-chip',{class:"v-chip-light-bg error--text font-weight-semibold",attrs:{"small":"","color":"error"}},[_vm._v(" ending ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'checkout-membership-view', params: { id: item.id} })}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_c('span',[_vm._v("Details")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }