<template>
  <v-card>
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>

        <v-text-field
          v-model="userQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search User Email"
          class="invoice-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="listTable"
      :options.sync="options"
      :server-items-length="totalListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        {{ item.id }}
      </template>

      <!-- plan -->
      <template #[`item.plan_id`]="{item}">
        <router-link
          :to="{ name: 'checkout-membership-view', params: { id: item.id} }"
          class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
        >
          {{ t(item.plan.sku.name) }}
        </router-link>
      </template>

      <!-- user -->
      <template #[`item.user_id`]="{item}">
        <span v-if="item.user">{{ item.user.email }}</span>
        <small v-else>(Not Yet Redeemed)</small>
      </template>

      <!-- group owner -->
      <template #[`item.group_id`]="{item}">
        <span v-if="item.group">{{ item.group.user.email }}</span>
      </template>

      <template #[`item.next_billing_at`]="{item}">
        <span v-if="item.next_billing_at">{{ item.next_billing_at | dateSimple }}</span>
        <span
          v-if="item.next_billing_at"
          class="ml-2"
        >({{ item.next_billing_at | diff('days') }} days)</span>
      </template>

      <template #[`item.started_at`]="{item}">
        <span v-if="item.started_at">{{ item.started_at | dateSimple }}</span>
      </template>

      <template #[`item.ended_at`]="{item}">
        <span v-if="item.ended_at">{{ item.ended_at | dateSimple }}</span>
      </template>

      <!-- active -->
      <template #[`item.active`]="{item}">
        <!-- chip -->
        <v-chip
          small
          :color="mapColor(item.active)"
          :class="`v-chip-light-bg ${mapColor(item.active)}--text font-weight-semibold`"
        >
          {{ item.active ? 'Active' : 'Ended' }}
        </v-chip>
        <v-chip
          v-if="item.ending"
          small
          color="error"
          :class="`v-chip-light-bg error--text font-weight-semibold`"
        >
          ending
        </v-chip>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="$router.push({ name: 'checkout-membership-view', params: { id: item.id} })"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiEye }}
                </v-icon>
                <span>Details</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiEye, mdiFileDocumentOutline } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { dateSimple, diff } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import useMembershipList from './useMembershipList'

export default {
  filters: { dateSimple, diff },

  setup() {
    const {
      listTable,
      searchQuery,
      userQuery,
      tableColumns,
      options,
      totalListTable,
      loading,
      selectedTableData,
      loadMemberships,
      statusFilter,
      statusOptions,
    } = useMembershipList()

    const refundRequest = ref(null)
    const showRequest = ref(false)

    const setItem = item => {
      refundRequest.value = item
      showRequest.value = true
    }

    const closeRequest = () => {
      refundRequest.value = null
      showRequest.value = false
      console.log('close', refundRequest.value)
      loadMemberships()
    }
    const mapColor = active => (active ? 'success' : '')

    return {
      tableColumns,
      searchQuery,
      userQuery,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,
      statusFilter,
      statusOptions,

      t,

      setItem,
      refundRequest,
      showRequest,
      closeRequest,
      mapColor,

      icons: {
        mdiEye,
        mdiDotsVertical,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice-list-search {
  max-width: 10.625rem;
}
.invoice-list-status {
  max-width: 11.3rem;
}
</style>
